<template>
  <main-layout>
    <div id="driver-request-list">
      <div class="row">
        <div class="col-md-4 left-part">
          <div class="left-content">
            <img
              src="/static/img/glp-logomark@2x.png"
              alt="GLP logo"
              class="logo center"
            />

            <h2>Lista de Solicitações</h2>
            <p
              v-if="requestList.length === 0"
              style="text-align: center; margin-bottom: 30px"
            >
              <b>{{
                isLoading ? 'Carregando...' : 'Nenhuma visita encontrada'
              }}</b>
            </p>
            <div class="request-list" v-else>
              <div v-for="request in requestList" :key="request.idrequest">
                <driver-request-item
                  :requestItem="request"
                  :isDriver="true"
                ></driver-request-item>
              </div>
            </div>
          </div>
          <DriverFooter />
        </div>
        <div class="col-md-8 no-padding">
          <div
            class="image-box"
            style="background-image: url('./static/img/glp-main.png')"
          ></div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import requestMixin from '../../mixins/request.mixin.js'
import MainLayout from '../../layouts/Main.vue'
import DriverFooter from '@/components/DriverFooter.vue'
import DriverRequestItem from '@/components/DriverRequestItem.vue'
export default {
  mixins: [requestMixin],
  components: {
    MainLayout,
    DriverFooter,
    DriverRequestItem,
  },
  data() {
    return {
      requestList: [],
      isLoading: true,
    }
  },
  mounted() {
    this.getRequestList()
  },
  methods: {
    test(id) {
      console.log(id)
    },
    async getRequestList() {
      try {
        this.isLoading = true
        await this.listRequestsMixin().then((response) => {
          this.requestList = response.requestList
        })
      } catch (error) {
        console.error('Error getting request list:', error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scooped lang="scss">
@import '../../scss/colors.scss';

#driver-request-list {
  .left-part {
    background-color: $lightgray;
    min-height: 100vh;
    .left-content {
      padding: 90px 70px;
      padding-bottom: 100px;

      .logo {
        height: 45px;
        margin-bottom: 90px;
        object-fit: contain;
      }

      h2 {
        text-align: center;
        margin-bottom: 30px;
      }

      .request-list {
        display: grid;
        gap: 5px;
        margin-top: 50px;
      }
    }
  }

  .image-box {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  #driver-request-list {
    .left-part {
      .left-content {
        padding: 30px 40px;
        padding-bottom: 80px;

        .logo {
          margin-bottom: 40px;
        }

        h2 {
          margin-bottom: 45px;
        }
      }
    }
  }
}
</style>
